import React, { useState, useMemo } from "react"
import { graphql } from "gatsby"

import Layout from "../theme/layout"
import SEO from "../components/header/seo"
import ShopPageContent from "../components/shop-page/shop-page-content"

const ShopPage = ({ pageContext, data, location }) => {
  // Get all products from query
  const allCategories = data.allSeaCategory.nodes || []
  const allProducts = data.allSeaProduct.nodes || []
  const allAttributes = data.allSeaProductAttribute.nodes || []

  return (
    <Layout pathContext={pageContext} location={location}>
      <SEO title={"Shop | Nutribrands"} location={location} />
      <ShopPageContent
        title={"Shop"}
        categories={allCategories}
        products={allProducts}
        attributes={allAttributes}
      />
    </Layout>
  )
}

export default ShopPage

// ===============
//      QUERY
// ===============
export const query = graphql`
  {
    # Only parent categories should be shown on this page
    allSeaCategory(filter: { parent_id: { eq: 0 } }) {
      nodes {
        title
        slug
        parent_id
        category_slug_path
      }
    }
    allSeaProductAttribute {
      nodes {
        key
        name
        values {
          count
          database_id
          name
          slug
        }
      }
    }
    allSeaProduct(sort: { fields: categories }) {
      nodes {
        ...categoryPageProducts
      }
    }
  }
`
